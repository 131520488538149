a {
  text-decoration: none;
  color: white;
}
body {
  font-family: Helvetica;
}
ul,
li {
  margin: 0;
  padding: 0;
}
