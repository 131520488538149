header {
  width: 100%;
  height: 80px;
  background: rgb(245, 245, 220);
  background: linear-gradient(
    90deg,
    rgba(245, 245, 220, 1) 35%,
    rgba(110, 51, 0, 1) 93%
  );
  position: fixed;
  z-index: 999;
}
header ul {
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  margin: 0 auto;
  list-style: none;
  display: flex;
  flex-direction: row;
  padding-left: 1px;
}
header li {
  font-size: 16px;
  margin-top: 30px;
  padding-inline: 40px;
  font-weight: 600;
  color: black;
  cursor: pointer;
}
header li:hover {
  border-bottom: 2px solid black;
}
header img {
  height: 80px;
  width: 80px;
}
header a {
  color: black;
}
nav ul li {
  position: relative;
}

nav ul li:hover .sous {
  display: block;
}
.appears:hover {
  border-bottom: none;
}
.sous {
  display: none;
  position: absolute;
  width: 147px;
  left: -10%;
  margin-right: 10px;
  padding-top: 0.2px;
  padding: 5px;
  z-index: 1000;
}

.sous li {
  font-size: 14px;
  float: none;
  text-align: left;
  width: 110px;
  margin-top: 5px;
  margin-left: 10px;
  margin-bottom: 10px;
}
.sous li:hover {
  border-bottom: none;
}
.rdv {
  margin-top: 13px;
  margin-bottom: 13px;
  font-size: 16px;
  font-weight: 600;
  background-color: #f5f5db;
  border: none;
  border-radius: 30px;
  padding: 10px;
  outline: none;
  cursor: pointer;
}
header h1 {
  display: none;
  font-weight: 300;
}

@media (max-width: 1180px) {
  header ul {
    width: 88%;
  }
}
@media (max-width: 640px) {
  header img,
  header button {
    display: none !important;
  }
  header ul {
    display: flex !important;
    justify-content: center !important;
  }
}
@media (max-width: 1070px) {
  header h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center !important;
  }
  header li {
    display: none !important;
  }
  header img {
    display: flex;
    justify-content: flex-start;
  }
}
/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 979px) {
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
}

/* Landscape phones and down */
@media (max-width: 480px) {
}

@media (max-width: 1540px) {
  .re {
    display: none;
  }
  header ul {
    display: flex;
    justify-content: space-evenly;
  }
  .appears {
    display: initial;
  }
}
@media (min-width: 1540px) {
  .appears {
    display: none;
  }
}
