.soin-section {
  width: 100%;
}
.soin-section img {
  height: 400px;
  width: 100%;
  object-fit: cover;
  padding-bottom: 15px;
}
.magnee {
  height: 220px !important;
  width: 100% !important;
}
.soin-section h3 {
  position: absolute;
  left: 30%;
  top: 15%;
  color: white;
  font-size: 40px;
  font-weight: 600;
}
.soins {
  padding-bottom: 100px;
}
.soinss {
  padding-bottom: 100px;
}
.infos-soins {
  padding-top: 50px;
  width: 70%;
  margin: 0 auto;
  padding-bottom: 50px;
}
.soins {
  display: flex;
  flex-direction: row;
}
.soinss {
  display: flex;
  flex-direction: row;
}
.soinss img,
.soins img {
  object-fit: contain;
}
.infos-soins .info-text {
  width: 50%;
  padding-right: 30px;
  padding-left: 30px;
  line-height: 25px;
}
.infos-soins img {
  height: 350px;
  width: 100%;
  padding-top: 20px;
  border-radius: 30px;
  cursor: pointer;
}
.infos-soins a {
  width: 50%;
}
@media (max-width: 1540px) {
  .infos-soins {
    width: 70%;
  }
}
@media (max-width: 480px) {
  .infos-soins p {
    display: none;
  }
  .infos-soins {
    width: 80%;
  }
  .soins {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
  }
  .soinss {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 80px;
  }
  .infos-soins a {
    width: 100%;
  }
  .infos-soins .info-text {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
  }
  .soin-section h3 {
    position: absolute !important;
    left: 10% !important;
  }
}
@media (max-width: 900px) {
  .infos-soins p {
    display: none;
  }
  .infos-soins {
    width: 80%;
  }
  .soins {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
  }
  .soinss {
    display: flex;
    flex-direction: column-reverse !important;
    padding-bottom: 80px;
  }
  .infos-soins a {
    width: 100%;
  }
  .infos-soins h2 {
    text-align: center;
  }
  .infos-soins .info-text {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
  }
  .soin-section h3 {
    position: absolute;
    left: 20%;
  }
}
@media (max-width: 500px) {
  .infos-soins img {
    height: 250px;
  }
}
