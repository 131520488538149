.footer-dark {
  padding: 20px 0;
  color: #f0f9ff;
  background-color: #87562c;
}
footer section {
  display: flex;
  flex-direction: row;
}
footer {
  height: 120px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
footer h3 {
  margin-top: 0px;
  margin-bottom: 2px;
}
footer li {
  height: 20px;
  padding-left: 8px;
  padding-top: 3px;
}
footer ul {
  list-style: none;
  margin-left: 0px;
  padding-left: 0px;
  line-height: 25px;
}
footer section {
  width: 60%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}
.icon {
  padding-top: 4px;
}
.oneli {
  padding-bottom: 7px;
}
/* .seccs {
  padding-right: 120px;
} */
/* .seccs1 {
  padding-right: 200px;
} */
.icon2 {
  padding-left: 50px;
  padding-top: 25px;
  font-size: 45px;
}
/* .spacing {
  padding-right: 240px;
} */
.seccs12 {
  padding-left: 80px;
}
.newsletter {
  padding-bottom: 25px;
}
footer input,
footer button {
  outline: none;
  cursor: pointer;
}
.conn {
  padding-bottom: 8px;
}

@media (max-width: 1540px) {
  footer section {
    width: 70%;
  }
}
@media (max-width: 800px) {
  footer section {
    display: flex;
    flex-direction: column;
  }
  footer {
    height: 500px !important;
  }
  .seccs12 {
    padding-left: 0px;
  }
  footer section {
    width: 80%;
  }
}
@media (max-width: 1200px) {
  footer section {
    width: 80%;
  }
}
@media (max-width: 1050px) {
  footer section {
    display: flex;
    flex-direction: column;
  }
  footer {
    height: 440px !important;
  }
  .seccs12 {
    padding-left: 0px;
  }
  footer ul {
    padding-top: 10px;
  }
  footer button {
    max-width: 300px;
  }
  footer input {
    max-width: 286px;
  }
  footer h3 {
    padding-top: 20px;
  }
  .icon2 {
    padding-top: 10px;
    padding-left: 1px;
  }
}
@media (max-width: 500px) {
  footer li {
    padding-top: 5px;
    padding-left: 0px;
  }
  .address {
    padding-bottom: 10px;
  }
  footer button {
    padding-bottom: 20px;
  }
}
@media (max-width: 1400px) {
  footer {
    height: 170px;
  }
}
@media (max-width: 1200px) {
  footer {
    height: 200px;
  }
}
@media (max-width: 1000px) {
  footer {
    height: 400px;
  }
}
