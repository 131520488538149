form {
  width: 55%;
  margin: 0 auto;
}
.column {
  display: flex;
  flex-direction: column;
}
.helmet {
  height: 600px;
  margin-right: 60px;
  border: 5px solid black;
}
.formm {
  display: flex;
  justify-content: center;
  height: 750px;
}
.formm input {
  font-family: "Interstate";
  font-size: 17px;
  border: none;
  margin-left: 20px;
  margin-bottom: 50px;
  outline: none;
  width: 600px;
  border-bottom: 1px solid black;
}
.name {
  padding-bottom: 20px;
  color: black;
}
.nom {
  margin: 30px;
  color: black;
  padding-bottom: 20px;
}
.email {
  color: black;
  margin: 30px;
  padding-bottom: 20px;
}
.tel {
  color: black;
  margin: 30px;
  padding-bottom: 20px;
}
::-webkit-input-placeholder {
  color: black;
}
.titre-cont {
  font-family: "Interstate";
  text-align: center;
  margin-bottom: 25px;
  font-size: 40px;
  color: black;
}
.souhaite {
  font-family: "Serif";
  font-weight: 500;
  color: black;
  font-size: 23px;
  margin: 0 auto;
  text-align: center;
  line-height: 38px;
}
textarea {
  margin-top: 70px;
  font-family: "Interstate";
  font-size: 17px;
  margin-left: 30px;
  height: 40px;
  width: 600px;
  border: none;
  outline: none;
  border-bottom: 1px solid black;
  margin-bottom: 50px;
  margin-top: 20px;
  resize: none;
}
.envoy {
  font-family: "Interstate";
  margin-top: 70px;
  font-size: 14px;
  border: none;
  background-color: black;
  color: white;
  width: 300px;
  height: 40px;
  outline: none;
  cursor: pointer;
}
.envoy:hover {
  background-color: #87562c;
  color: black;
}
.envoyer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
a {
  text-decoration: none;
}
@media (max-width: 1280px) {
  .formm input {
    width: 500px;
  }
  textarea {
    width: 500px;
  }
}
@media (max-width: 480px) {
  .formm {
    padding-top: 250px;
    width: 85%;
    margin: 0 auto;
  }
  .formm input {
    width: 250px;
  }
  .titre-cont {
    font-size: 30px;
  }
  textarea {
    width: 250px;
  }
  .envoy {
    width: 250px;
    margin-right: 20px;
  }
  .row {
    display: flex;
    flex-direction: column;
  }
  textarea {
    margin-left: 20px;
  }
}
@media (max-width: 1200px) {
  .formm {
    padding-top: 250px;
    width: 90%;
    margin: 0 auto;
  }
  .row {
    display: flex;
    flex-direction: column;
  }
}

.contact-container {
  width: 80%;
  min-height: 950px;
  margin: 0 auto;
  display: flex;

  flex-direction: column;
}
/* .contact-container button {
  margin-top: 40px;
  width: 200px;
  border: none;
  font-weight: 700;
  background-color: #fff;
  height: 50px;
  border-radius: 50px;
  cursor: pointer;
} */
.end {
  display: flex;
  justify-content: flex-end;
  padding-top: 50px;
  padding-bottom: 50px;
}
.contact-container h2 {
  padding-top: 110px;
  text-align: center;
}

.resize {
  resize: vertical;
}
.column {
  display: flex;
  flex-direction: column;
}
.rwow {
  display: flex;
  flex-direction: row;
}
form input {
  margin-top: 10px;
  border: none;
  width: 300px;
  height: 45px;
  outline: none;
  cursor: pointer;
}
form textarea {
  margin-top: 40px;
  height: 45px;
  width: 300px;
}
.link--manko {
  color: #b1c0b2;
  font-family: "Playfair Display", serif;
  font-weight: 900;
  font-style: italic;
  padding: 0.65em 0 0.8em;
  -webkit-transition: color 0.5s;
  transition: color 0.5s;
  font-size: 70px;
}

.link--manko:hover {
  color: #1e1a1b;
}

.link--manko::before {
  width: 50%;
  left: 25%;
  height: 80%;
  top: 10%;
}

.link--manko::after {
  width: 30%;
  left: 35%;
  height: 100%;
  top: 0;
}

.link--manko:hover::before,
.link--manko:hover::after {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.link--manko span {
  color: 87562c;
  font-weight: 400;
  position: absolute;
  font-size: 30px;
  left: 0;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, transform 0.5s;
}

.link--manko span:first-of-type {
  bottom: 100%;
  margin-bottom: 15px;
  -webkit-transform: scale3d(0.8, 0.8, 1) translate3d(0, 10px, 0);
  transform: scale3d(0.8, 0.8, 1) translate3d(0, 10px, 0);
}

.link--manko span:last-of-type {
  top: 100%;
  margin-top: 10px;
  -webkit-transform: scale3d(0.8, 0.8, 1) translate3d(0, -10px, 0);
  transform: scale3d(0.8, 0.8, 1) translate3d(0, -10px, 0);
}

.link--manko:hover span {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.link--manko:hover span:first-of-type {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

.link--manko:hover span:last-of-type {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.input {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin: 1em;
  max-width: 400px;
  width: calc(100% - 2em);
  vertical-align: top;
}

.input__field {
  position: relative;
  display: block;
  float: right;
  padding: 0.8em;
  width: 60%;
  border: none;
  border-radius: 0;
  background: #f0f0f0;
  color: #aaa;
  font-weight: 400;
  font-family: "Avenir Next", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-appearance: none; /* for box shadows to show on iOS */
}

.input__field:focus {
  outline: none;
}

.input__label {
  display: inline-block;
  float: right;
  padding: 0 1em;
  width: 40%;
  color: #6a7989;
  font-weight: bold;
  font-size: 70.25%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.input__label-content {
  position: relative;
  display: block;
  padding: 1.6em 0;
  width: 100%;
}

.graphic {
  position: absolute;
  top: 0;
  left: 0;
  fill: none;
}

.icon {
  color: #ddd;
  font-size: 150%;
}

.input--nao {
  overflow: hidden;
  padding-top: 1em;
}

.input__field--nao {
  padding: 0.5em 0em 0.25em;
  width: 100%;
  background: transparent;
  color: black;
  font-size: 1.25em;
  margin-top: 20px;
}

.input__label--nao {
  position: absolute;
  top: 0.95em;
  font-size: 0.85em;
  left: 0;
  display: block;
  width: 100%;
  text-align: left;
  padding: 0em;
  pointer-events: none;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transition: -webkit-transform 0.3s 0.1s, color 1s;
  transition: transform 0.3s 0.1s, color 1s;
  -webkit-transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
}

.graphic--nao {
  stroke: #92989e;
  pointer-events: none;
  -webkit-transition: -webkit-transform 0.7s, stroke 0.7s;
  transition: transform 0.7s, stroke 0.7s;
  -webkit-transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
}

.input__field--nao:focus + .input__label--nao,
.input--filled .input__label--nao {
  color: #87562c;
  -webkit-transform: translate3d(0, -1.25em, 0) scale3d(0.75, 0.75, 1);
  transform: translate3d(0, -1.25em, 0) scale3d(0.75, 0.75, 1);
}

.input__field--nao:focus ~ .graphic--nao,
.input--filled .graphic--nao {
  stroke: #87562c;
  -webkit-transform: translate3d(-66.6%, 0, 0);
  transform: translate3d(-66.6%, 0, 0);
}

.info-section {
  padding-top: 80px;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.infopart1 {
  width: 33%;
}
.infopart1 h4 {
  text-align: center;
  color: #87562c;
  margin-bottom: 0px;
}
.infopart1 p {
  font-weight: 700;
  font-size: 20px;
  cursor: pointer;
  text-align: center;
}
.centering {
  text-align: center;
}

.button {
  cursor: pointer;
  float: left;
  min-width: 150px;
  max-width: 250px;
  display: block;
  margin: 1em;
  padding: 1em 2em;
  border: none;
  background: none;
  color: inherit;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}
.button:focus {
  outline: none;
}
.button > span {
  vertical-align: middle;
}
.end button {
  color: #000;
  background-color: #fff;
}
.button--text-upper {
  letter-spacing: 2px;
  text-transform: uppercase;
}
.button--text-thin {
  font-weight: 300;
}
.button--text-medium {
  font-weight: 500;
}
.button--text-thick {
  font-weight: 600;
}
.button--round-s {
  border-radius: 5px;
}
.button--round-m {
  border-radius: 15px;
}
.button--round-l {
  border-radius: 40px;
}

.button--border-thin {
  border: 1px solid;
  border-radius: 50px;
}

.button--rayen {
  overflow: hidden;
  padding: 0;
  width: 230px;
}
.button--rayen.button--inverted {
  color: #fff;
}
.button--rayen::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #87562c;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  color: #fff;
}
.contact-container h5 {
  text-align: center;
  font-size: 21px;
  font-weight: 400;
}
.button--rayen > span {
  display: block;
}
.button--rayen::before,
.button--rayen > span {
  padding: 1em 2em;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button--rayen:hover::before {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.button--rayen:hover > span {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

@media (max-height: 900px) {
  .contact-container {
    height: 1100px;
  }
}

@media (max-width: 1500px) {
  .info-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
  }
  .contact-container {
    min-height: 1380px;
  }
  .infopart1 {
    padding-top: 30px;
    width: 60%;
  }
}
@media (max-width: 700px) {
  .link--manko {
    font-size: 50px;
  }
  .contact-container {
    width: 70%;
  }
}
@media (max-width: 500px) {
  .link--manko {
    font-size: 50px;
  }
  .contact-container {
    width: 80%;
  }
  form {
    width: 90%;
  }
}
