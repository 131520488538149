.home {
  width: 100%;
}
.home img {
  min-width: 100%;
  width: 100%;
  height: 700px;
  object-fit: cover;
}
.slick-next {
  display: none !important;
}
.home h1 {
  position: absolute;
  line-height: 80px;
  top: 24%;
  left: 30%;
  color: white;
  font-size: 40px;
  font-weight: 700;
  width: 50%;
  margin: 0 auto;
}
h1 h2 {
  font-size: 25px;
  font-weight: 500;
}
.info-home {
  width: 50%;
  margin: 0 auto;
  padding-bottom: 40px;
}
.info-home h3 {
  font-size: 35px;
  font-weight: 300;
  margin-bottom: 4px;
  text-align: center;
}
.info-home h4 {
  font-size: 20px;
}
.info-home div {
  border: 2px solid #87562c;
  width: 60px;
  margin: 0 auto;
  margin-bottom: 40px;
}
.liste-home ul {
  margin-top: 1px;
}
.home p {
  line-height: 30px;
}
.session-boxes {
  width: 70%;
  margin: 0 auto;
  padding-bottom: 100px;
}
.session img {
  padding-top: 50px;
  width: 300px;
  height: 300px;
  object-fit: contain;
}
.session {
  width: 300px;
  padding: 50px;
  cursor: pointer;
}
.session h4 {
  font-size: 16px;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: center;
  background-color: lightgray;
}
.bgc {
  background-color: lightgray;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.row {
  display: flex;
  flex-direction: row;
}
.liste-home ul {
  padding-left: 20px;
}
@media (max-width: 1540px) {
  .session {
    width: 200px;
  }
  .session img {
    width: 200px;
    height: 220px;
  }
  .session-boxes {
    display: flex;
    justify-content: space-evenly;
  }
  .home h1 {
    line-height: 60px;
  }
}
@media (max-width: 850px) {
  .home h1 {
    position: absolute;
    left: 18%;
    width: 70%;
  }
}
@media (max-width: 480px) {
  .home h1 {
    font-size: 30px;
    line-height: 50px;
  }
  .info-home {
    width: 80%;
  }
}
