body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* MENU */

.menu {
  width: 100%;
  display: block;
  text-align: center;
  padding: 0px;
}
.menu ul {
  position: relative;
  display: flex;
  flex-direction: column;
  top: 0px;
  font-size: 18px;
  padding: 0px;
}
.menu li {
  list-style: outside none none;
  margin: 10px 0px;
  padding: 0;
  cursor: pointer;
}
.menu a {
  color: black;
}
.menu a:hover {
  color: #0f6c74;
}

.burger-menu,
.burger-menu.open {
  display: inline-block;
  cursor: pointer;
  position: fixed;
  left: 15px;
  top: 12px;
  z-index: 9999;
  background: #f5f5db;
  padding: 10px;
  border-radius: 25px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}

.burger-menu .bar1,
.bar2,
.bar3 {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 4px 0;
  transition: 0.4s;
}

.burger-menu.open .bar1 {
  -webkit-transform: rotate(-45deg) translate(-4px, 4px);
  transform: rotate(-45deg) translate(-4px, 4px);
}

.burger-menu.open .bar2 {
  opacity: 0;
}

.burger-menu.open .bar3 {
  -webkit-transform: rotate(45deg) translate(-6px, -6px);
  transform: rotate(45deg) translate(-6px, -6px);
}

@media (min-width: 1070px) {
  .burger-menu,
  .burger-menu.open {
    display: none;
  }
}
