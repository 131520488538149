.qui-section {
  width: 100%;
}

.qui-section img {
  height: 400px;
  width: 100%;
  object-fit: cover;
  padding-bottom: 15px;
}
.qui-section ul {
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 30px;
}
.qui-section h3 {
  position: absolute;
  left: 30%;
  top: 15%;
  color: white;
  font-size: 40px;
  font-weight: 600;
}
.info-perso {
  width: 70%;
  margin: 0 auto;
}
.info-perso img {
  padding-top: 15px;
  padding-right: 25px;
  width: 400px;
  height: 500px;
  border-radius: 30px;
}
.info-perso p {
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;
}
.logo-social {
  padding-left: 100px;
}
.logo-social img {
  width: 50px;
  height: 50px;
  cursor: pointer;
  padding-bottom: 70px;
}
.border {
  border: 2px solid #87562c;
  width: 30%;
  margin: 0 auto;
  margin-top: 40px;
}
.finishingline {
  text-align: center;
  font-size: 28px;
  padding-bottom: 100px;
}

@media (max-width: 1540px) {
  .qui-section h3 {
    position: absolute;
    left: 25%;
  }
  .info-perso {
    width: 60%;
  }
}
@media (max-width: 500px) {
  .info-perso img {
    width: 300px;
    height: 350px;
  }
  .info-perso {
    width: 80%;
  }
  .qui-section h3 {
    position: absolute;
    top: 23%;
    left: 10%;
    font-size: 35px;
  }
  .finishingline {
    padding: 20px;
    font-size: 22px;
  }
}
