.review-container {
  height: 1000px;
}
.review-container img {
  height: 400px;
  width: 100%;
  object-fit: cover;
  padding-bottom: 50px;
}
.review-container h3 {
  position: absolute;
  left: 40%;
  top: 15%;
  color: white;
  font-size: 40px;
  font-weight: 600;
}
.review-card {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}

.card {
  height: 430px;
  width: 300px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 20px;
}
.card p {
  margin: 25px;
  color: gray;
  font-size: 14px;
  line-height: 22px;
}
.card h5 {
  margin-left: 25px;
}
.rating {
  padding-left: 25px;
  font-size: 20px;
  color: #63c2cb;
}
.star {
  padding-right: 4px;
}
.card h4 {
  padding-left: 25px;
  margin-top: 5px;
}
h5 svg {
  color: #63c2cb;
  font-size: 16px;
}
#more {
  opacity: 0;
  transition: 0.25s ease;
}
.sett {
  display: flex;
  justify-content: space-between;
}
.arroww {
  padding-top: 20px;
  padding-right: 20px;
  font-size: 20px;
  cursor: pointer;
}
.card button {
  border: none;
  outline: none;
  background-color: #fff;
}
@media (max-width: 500px) {
  .review-container h3 {
    position: absolute;
    top: 20%;
    left: 15%;
  }
  .review-card {
    width: 90%;
  }
  .review-container {
    height: 2500px !important;
  }
}
@media (max-width: 850px) {
  .review-container {
    height: 1700px;
  }
  .review-card {
    width: 90%;
  }
}
@media (max-width: 750px) {
  .review-container {
    height: 2500px !important;
  }
  .review-card {
    width: 60% !important;
  }
}
@media (max-width: 1700px) {
  .review-container {
    height: 1500px;
  }
}
@media (max-width: 1275px) {
  .review-card {
    width: 60%;
  }
}
@media (max-width: 1130px) {
  .review-card {
    width: 35%;
  }
  .review-container {
    height: 2500px;
  }
}
@media (max-width: 950px) {
  .review-card {
    width: 45%;
  }
}
@media (max-width: 550px) {
  .review-card {
    width: 70% !important;
  }
}
@media (max-width: 400px) {
  .review-card {
    width: 90% !important;
  }
}
@media (max-width: 480px) {
  .review-card {
    width: 90% !important;
  }
}
