.magne-section {
  position: relative;
  width: 100%;
}
.infos-soins-details {
  padding-top: 50px;
  width: 70%;
  margin: 0 auto;
  padding-bottom: 50px;
}
.magne-section img {
  position: relative;
  height: 350px;
  width: 100%;
  object-fit: contain;
  padding-bottom: 15px;
  padding-top: 100px;
}
.magne-section ul {
  margin-top: 20px;
  margin-left: 20px;
}
.im h3 {
  position: absolute;
  left: 40%;
  top: 8%;
  color: white;
  font-size: 50px;
  font-weight: 600;
}
.info-text-m {
  width: 100%;
  margin: 0 auto;
  padding-right: 30px;
  padding-left: 30px;
  line-height: 25px;
}
.magne-section h2 {
  padding-top: 15px;
}
.red {
  display: flex;
  justify-content: space-between;
  padding-bottom: 50px;
}
.magne-section h1 {
  font-size: 32px;
  line-height: 35px;
  font-weight: 400;
  text-align: center;
  padding-top: 20px;
}
.tarif-section {
  background-color: #d1c3a4;
  width: 100%;
}
.tarif-boxes {
  padding-top: 20px;
  width: 70%;
  margin: 0 auto;
  padding-bottom: 50px;
}
.tarif-boxes button {
  margin-top: 30px !important;
  width: 220px;
  margin: 0 auto;
  padding: 15px;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  background-color: #b09370;
  outline: none;
  cursor: pointer;
  border-radius: 30px;
  border: none;
}
.tarif-boxes a {
  color: black !important;
}
.tarif-boxes a:hover {
  color: white !important;
}
.tarif-boxes div {
  margin: 0 auto;
}
.rdv-button {
  text-align: center;
}
.main-box {
  background-color: #f7f7f7;
  width: 100%;
  height: 150px;
  border-radius: 0px 0px 30px 30px;
}
.main-box p {
  padding-top: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #87562c;
  font-weight: 700;
  font-size: 40px;
}
.header-box {
  width: 100%;
  height: 80px;
  background-color: #b09370;
  border-radius: 30px 30px 0px 0px;
}
.reverse {
  display: flex;
  flex-direction: row;
}
.header-box p {
  padding-top: 10px;
  text-align: center;
  font-size: 21px;
  color: white;
  font-weight: 400;
  margin-top: 7px;
  margin-bottom: 0px;
}
.age {
  font-size: 15px !important;
  padding-top: 3px !important;
  font-weight: 300 !important;
}
.lieu {
  font-size: 15px !important;
  font-weight: 400 !important;
  padding-top: 7px !important;
  color: black !important;
}
.size-box {
  width: 24%;
  padding-right: 30px;
}

@media (max-width: 1300px) {
  .tarif-boxes {
    width: 90%;
  }
}
@media (max-width: 1000px) {
  .tarif-boxes {
    width: 85%;
  }
  .reverse {
    display: flex;
    flex-direction: column;
  }
  .size-box {
    width: 90%;
    margin: 0 auto;
    padding-bottom: 40px;
  }
  .tarif-boxes button {
    width: 400px;
    font-size: 22px;
  }
  .infos-soins-details {
    width: 80%;
    margin: 0 auto;
  }
  @media (max-width: 500px) {
    .tarif-boxes button {
      width: 250px;
      font-size: 19px;
    }
    .info-text-m {
      padding-right: 0px;
      padding-left: 0px;
    }
    .size-box {
      padding-right: 0px;
    }
    .magne-section h1 {
      font-size: 27px;
    }
  }
  .magne-section img {
    padding-top: 140px;
    height: 250px;
  }
  .magne-section {
    width: 90%;
    margin: 0 auto;
  }
  .infos-soins-details {
    width: 90%;
  }
}
